import { FC, useState } from "react";
import styled from "styled-components";
import { Download, Loader } from "react-feather";
import { handleAuthentication } from "@frontend/authentication";
import ImageAPI from "../constants/apis";
import { toast } from "react-toastify";

interface iFile {
	oldSize: number;
	name: string;
	size: number;
	url: string;
	id: string;
}

interface Button {
	files: iFile[];
	outputType: string;
}

interface Auth {
	token: string;
}

const DownloadButton: FC<Button> = ({ files, outputType }) => {
	const [loading, setLoading] = useState(false);

	const downloadFile = async () => {
		if (loading) return;
		setLoading(true);

		for (let x = 0; x < files.length; x++) {
			if (!files[x].url) {
				toast.warn("Please wait for all images to finish uploading", {
					toastId: "toastMessage",
				});
				setLoading(false);
				return;
			}
		}

		//@ts-ignore
		const { token }: Auth = await handleAuthentication();
		const options = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: `Bearer ${token}`,
			},
			responseType: "blob",
		};
		for (let x = 0; x < files.length; x++) {
			const current = files[x];
			//@ts-ignore
			const response = await ImageAPI.get(current.url, options);

			if (response) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					`${current.name.split(".")[0]}-Resized.${outputType}`
				);
				document.body.appendChild(link);
				link.click();
			} else {
				toast.error("An error has occurred while downloading all images", {
					toastId: "toastMessage",
				});
				setLoading(false);
				return;
			}
		}
		toast.success("All images downloaded", { toastId: "toastMessage" });

		setLoading(false);
	};

	return (
		<div style={{ marginTop: "30px", marginBottom: "60px" }}>
			<ButtonDownload
				onClick={() => {
					downloadFile();
				}}
				download
				data-qa="download-all-button"
			>
				{loading ? (
					<>
						<Loader />
						Downloading All...
					</>
				) : (
					<>
						<Download />
						Download All
					</>
				)}
			</ButtonDownload>
		</div>
	);
};

const ButtonDownload = styled.a`
	flex-grow: 0
	transition: background 350ms ease-in-out;
	border-radius: 3px;
	background: var(--success);
	padding: 2rem;
	margin-bottom: 6rem;
	color: var(--white);
	text-decoration: none;
	font-size: 1.5rem;
	cursor: pointer;
	svg {
		margin: 0 0.8rem -0.4rem 0;
	}
	&:hover {
		background: var(--success-hover);
	}
`;

export default DownloadButton;
