import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
	handleAuthentication,
	initializeAuthentication,
	getTokenFromStorage,
	isAuthenticated,
} from "@frontend/authentication";
import { store } from "./store";

import App from "./App";

const app = document.getElementById("root");

const render = () => {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		app
	);
};

initializeAuthentication()
	.then(() => handleAuthentication())
	.then(() => {
		return isAuthenticated().then(() => {
			if (getTokenFromStorage()) {
				render();
			}
		});
	})
	.catch((e) => console.error(e));
