import React, { FC } from "react";
import styled, { useTheme } from "styled-components";
import { Sun, Moon } from "react-feather";

interface SwitchProps {
	toggleTheme: any;
}

const Switch: FC<SwitchProps> = ({ toggleTheme }) => {
	const theme = useTheme();

	return (
		<Button onClick={toggleTheme} data-qa="light-dark-mode">
			{theme.themeName === "light" ? <Sun /> : <Moon color={theme.text} />}
		</Button>
	);
};

const Button = styled.button`
	border: 0;
	padding: 1rem;
	background: none;
	cursor: pointer;
`;

export default Switch;
