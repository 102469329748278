import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
	:root {
		--white: #fff;
		--border-color: hsl(0, 0%, 91%);
		--black: #363537;
		--success: hsl(169, 66%, 43%);
		--success-hover: hsl(169, 62%, 49%);
	}

	* {
		box-sizing: border-box;
	}

	@font-face {
		font-family: "Larsseit";
		src: url("/fonts/larsseit.woff2") format("woff2");
	}

	html {
		font-size: 62.5%;
	}

	body {
		margin: 0;
		background: ${({ theme }) => theme.body};
		color: ${({ theme }) => theme.text};
		transition: color 0.50s linear, background 0.50s linear;
		font-family: "Larsseit", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	}

	.Toastify {
		.Toastify__toast-body > div:last-child {
			font-size: 1.6rem;
		}
	}
`;

export default GlobalStyles;
