import React, { FC } from "react";
import styled from "styled-components";

interface IProgress {
	loadProgress: number;
}

const ProgressBar: FC<IProgress> = ({ loadProgress }) => (
	<ProgressContainer data-qa="progress-bar">
		<span>{loadProgress + "% loaded"}</span>
		<Progress max={100} value={loadProgress || 0} />
	</ProgressContainer>
);

const ProgressContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		position: absolute;
		z-index: 5;
	}
`;

const Progress = styled.progress`
	transition: width 1s;
	padding: 1rem 0;
	width: 275px;
	border-radius: 36px;
	background: var(--success);
`;

export default ProgressBar;
