import React, { FC } from "react";
import styled from "styled-components";
import Black from "../assets/BlackLogo.png";
interface LogoProps {
	src: string;
}

const Header: FC = () => {
	return (
		<>
			<StyledHeader>
				<Logo src={Black} />
				<Title>Image Resizing Tool</Title>
				<Subtitle>Files must be in JPEG, PNG, GIF, or TIFF format</Subtitle>
			</StyledHeader>
		</>
	);
};

const StyledHeader = styled.div`
	justify-content: center;
	align-items: center;
`;

const Title = styled.h1`
	text-align: center;
`;

const Subtitle = styled.h2`
	text-align: center;
`;

const Logo = styled.img<LogoProps>`
	height: 40%;
	width: 40%;
	display: block;
	margin-left: auto;
	margin-right: auto;
`;

export default Header;
