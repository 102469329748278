import axios from "axios";
const {
	bx_vars: {
		//@ts-ignore
		API_URL,
	},
} = window;

export default axios.create({
	baseURL: API_URL,
	//@ts-ignore
	crossDomain: true,
});
