import { FC, useState } from "react";
import styled from "styled-components";
import { Download, Loader } from "react-feather";
import { handleAuthentication } from "@frontend/authentication";
import ImageAPI from "../constants/apis";

interface Button {
	url: string;
	name: string;
	outputType: string;
}

interface Auth {
	token: string;
}

const DownloadButton: FC<Button> = ({ name, url, outputType }) => {
	const [loading, setLoading] = useState(false);

	const downloadFile = async (url: string) => {
		setLoading(true);
		//@ts-ignore
		const { token }: Auth = await handleAuthentication();
		const options = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: `Bearer ${token}`,
			},
			responseType: "blob",
		};
		//@ts-ignore
		const response = await ImageAPI.get(url, options);

		if (response) {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${name.split(".")[0]}-Resized.${outputType}`);
			document.body.appendChild(link);
			link.click();
		}
		setLoading(false);
	};

	return (
		<>
			{url && (
				<ButtonDownload
					onClick={() => {
						downloadFile(url);
					}}
					download
					data-qa="download-button"
				>
					{loading ? (
						<>
							<Loader />
							Downloading...
						</>
					) : (
						<>
							<Download />
							Download
						</>
					)}
				</ButtonDownload>
			)}
			{!url && (
				<ButtonDownload data-qa="download-button">
					<Download />
					Download
				</ButtonDownload>
			)}
		</>
	);
};

const ButtonDownload = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: background 350ms ease-in-out;
	border-radius: 3px;
	background: var(--success);
	padding: 1rem;
	color: var(--white);
	text-decoration: none;
	font-size: 1.5rem;
	cursor: pointer;
	svg {
		margin: 0 0.8rem 0 0;
	}
	&:hover {
		background: var(--success-hover);
	}
`;

export default DownloadButton;
