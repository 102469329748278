import React, { FC } from "react";
import styled from "styled-components";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { changeImageSize, changeOutputType } from "./../redux/imageUpload/imageSlice";
import { Button } from "@mui/material";
import { Square } from "react-feather";

const Form: FC<{}> = () => {
	const options = [
		{ value: "jpeg", label: "JPEG" },
		{ value: "png", label: "PNG" },
		{ value: "gif", label: "GIF" },
		{ value: "tiff", label: "TIFF" },
	];

	const dispatch = useAppDispatch();
	const imageSize = useAppSelector((state) => state?.image?.imageSize);

	return (
		<FormDiv data-qa="form">
			<p>Choose Output File Type</p>
			<Select
				options={options}
				placeholder={<div>Choose Output File Type</div>}
				onChange={(e: any) => {
					dispatch(changeOutputType(e.value));
				}}
			/>

			<p>Choose Image Size</p>
			<ButtonContainer>
				<Button
					startIcon={<Square />}
					size="large"
					onClick={() => {
						dispatch(changeImageSize("square"));
					}}
					variant={imageSize === "square" ? "contained" : "outlined"}
				>
					Square (480 x 480)
				</Button>
				<Button
					startIcon={<Square style={{ transform: "scale(1.2, 1.65)" }} />}
					onClick={() => {
						dispatch(changeImageSize("portrait"));
					}}
					size="large"
					variant={imageSize === "portrait" ? "contained" : "outlined"}
				>
					Portrait (480 x 660)
				</Button>

				<Button
					startIcon={<Square style={{ transform: "scale(1.65, 1.2)" }} />}
					onClick={() => {
						dispatch(changeImageSize("landscape"));
					}}
					size="large"
					variant={imageSize === "landscape" ? "contained" : "outlined"}
				>
					{" "}
					Landscape (660 x 480)
				</Button>
			</ButtonContainer>
		</FormDiv>
	);
};

const FormDiv = styled.div`
	margin: 2.5rem auto;
	max-width: 800px;
	justify-content: left;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	button {
		padding: 2rem 2.2rem;
		display: flex;
		flex-direction: column;
		@media (min-width: 767px) {
			flex-direction: row;
		}

		span {
			margin: 0 0 1rem 0;
			@media (min-width: 767px) {
				margin: 0 8px 0 -4px;
			}
		}
	}
`;

export default Form;
