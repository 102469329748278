import React, { useState, FC, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { Image, File, X } from "react-feather";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { imageUpload } from "../redux/imageUpload/thunks";
import { ConvertToKB } from "../constants/utils";
import { ACCEPTED_FILES } from "../constants";
import DownloadButton from "./DownloadButton";
import DownloadAllButton from "./DownloadAllButton";
import ProgressBar from "./ProgressBar";
import useTrait from "../hooks/useTrait";
import Form from "./Form";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_TEXT = "Drag & drop your image(s) here or click to browse";
const PREFORM_TEXT = "Please select options above";

interface iFile {
	oldSize: number;
	name: string;
	size: number;
	url: string;
	id: string;
}
interface State {
	image: any;
	load: any;
	outputType: string;
	imageSize: string;
}

const FileUpload: FC = () => {
	const [dropText, setDropText] = useState(PREFORM_TEXT);
	const [loading, setLoading] = useState(false);
	const optimizedImage = useTrait([]);
	const dispatch = useAppDispatch();
	const loadProgress = useAppSelector((state: { image: State }) => state?.image?.load);
	const outputType = useAppSelector((state: { image: State }) => state?.image?.outputType);
	const imageSize = useAppSelector((state: { image: State }) => state?.image?.imageSize);

	useEffect(() => {
		if (imageSize && outputType) {
			setDropText(DEFAULT_TEXT);
		}
	}, [outputType, imageSize]);

	const onDrop = async (file: iFile[]) => {
		setLoading(true);
		setDropText(DEFAULT_TEXT);
		const old = optimizedImage.get();
		optimizedImage.set(optimizedImage.get().concat(file));
		const imagesArr = file;
		if (imagesArr.length === 0) {
			toast.error("An error has occurred", { toastId: "toastMessage" });
			setLoading(false);
			return;
		}
		for (let count = 0; count < file.length; count++) {
			const id = uuidv4();

			const singleFile: iFile = file[count];

			const userData = [singleFile];

			imagesArr[count].id = id;

			const response = await dispatch(imageUpload({ id, userData }));

			try {
				if (response.meta.requestStatus === "rejected") {
					toast.warn("Please upload a valid image", { toastId: "toastMessage" });
				} else {
					toast.success("Images Converted", { toastId: "toastMessage" });
					optimizedImage.set(old.concat(imagesArr));
				}
				imagesArr[count] = {
					...response.payload,
					oldSize: singleFile.size,
					name: singleFile.name,
					id: id,
				};
				optimizedImage.set(old.concat(imagesArr));
			} catch (e) {
				console.error("Error ===", e);
			}
		}
		setLoading(false);
	};

	const deleteRow = (id: string) => {
		let newFiles = optimizedImage.get();
		newFiles = newFiles.filter(function (obj: iFile) {
			return obj.id !== id;
		});
		optimizedImage.set(newFiles);
	};

	const onEnter = () => setDropText("Drop your images!");
	const onLeave = () => setDropText(DEFAULT_TEXT);
	return (
		<>
			<Form />
			<FileUploader
				style={
					dropText === PREFORM_TEXT ? { cursor: "not-allowed" } : { cursor: "pointer" }
				}
			>
				<Dropzone
					disabled={!imageSize || !outputType || loading ? true : false}
					onDragLeave={onLeave}
					onDragEnter={onEnter}
					// @ts-ignore
					onDrop={onDrop}
					accept={ACCEPTED_FILES}
				>
					{({ getRootProps, getInputProps }) => (
						<DropZoneDiv {...getRootProps()}>
							<input {...getInputProps()} />
							<p>{dropText}</p>
							<Image style={{ transform: "scale(2, 2)" }} />
						</DropZoneDiv>
					)}
				</Dropzone>
			</FileUploader>
			{optimizedImage.get().map((file: iFile, index: number) => (
				<LoaderArea key={index}>
					<File />
					<FileName data-qa="file-name">{file.name}</FileName>
					<FileSizeText data-qa="file-size">
						{ConvertToKB(file?.oldSize || file?.size)}
					</FileSizeText>
					<ProgressBar loadProgress={loadProgress[file?.id]?.percent || 0} />
					<FileSizeTextNew data-qa="file-size-optimized">
						{ConvertToKB(file?.oldSize ? file?.size : 0) || ""}
					</FileSizeTextNew>
					{file?.url && (
						<>
							<DownloadButton
								name={file?.name}
								url={file?.url}
								outputType={outputType}
							/>
							<DeleteButton
								onClick={(e) => {
									e.preventDefault();
									deleteRow(file.id);
									toast.success(`${file.name} deleted`, {
										toastId: "toastMessage",
									});
								}}
							>
								<X />
							</DeleteButton>
						</>
					)}
				</LoaderArea>
			))}
			{optimizedImage.get().length > 0 && (
				<DownloadAllButton files={optimizedImage.get()} outputType={outputType} />
			)}
		</>
	);
};

const FileName = styled.h2`
	margin: 0;
	font-size: 1.45rem;
	font-weight: bold;
	margin-right: 2rem;
	color: var(--black);
`;

const FileSizeText = styled.p`
	margin: 0;
	font-size: 1.2rem;
	color: var(--black);
`;

const FileSizeTextNew = styled.p`
	margin: 0;
	font-size: 1.2rem;
	color: var(--black);
`;

const LoaderArea = styled.div`
	margin: 0 auto 1rem;
	border: 0.1rem solid var(--border-color);
	border-radius: 3px;
	background-color: var(--white);
	padding: 2rem 1.5rem;
	background: var(--white);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	svg {
		display: none;
	}
	@media (min-width: 768px) {
		svg {
			display: block;
		}
	}
`;

const DropZoneDiv = styled.div`
	text-align: center;
	height: 100%;
	width: 100%;
`;

const FileUploader = styled.div`
	margin: 2.5rem auto;
	max-width: 800px;
	height: 350px;
	background-color: lightgrey;
	background-size: cover;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	border: 0.5rem dashed var(--border-color);
	border-radius: 25px;
	font-size: 3rem;
	color: var(--white);
	text-shadow: 0px 3px 2px var(--black);
	box-shadow: rgb(0 0 0 / 8%) 0px 4px 24px;
`;

const DeleteButton = styled.a`
	cursor: pointer;
	margin-left: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: background 350ms ease-in-out;
	border-radius: 3px;
	background: var(--success);
	padding: 1rem;
	color: var(--white);
	text-decoration: none;
	font-size: 1.5rem;
	svg {
		margin: 0 0.8rem 0 0;
	}
	&:hover {
		background: var(--success-hover);
	}
`;

export default FileUpload;
