import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { lightTheme } from "./constants/Themes";
import { GlobalStyles, FileUpload, Header } from "./components";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
	const themeMode = lightTheme;

	return (
		<ThemeProvider theme={themeMode}>
			<GlobalStyles />
			<Container>
				<NavBar />
				<Header />
				<FileUpload />
			</Container>
			<ToastContainer
				position="top-center"
				autoClose={1000}
				newestOnTop={false}
				draggable={false}
				hideProgressBar
				closeOnClick
				theme="colored"
			/>
		</ThemeProvider>
	);
};

const Container = styled.div`
	position: relative;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 2rem;
`;

const NavBar = styled.nav`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 1rem 0;
`;

export default App;
