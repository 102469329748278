import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromStorage } from "@frontend/authentication";

import ImageAPI from "../../constants/apis";
import { ConvertToKB } from "../../constants/utils";
import { load } from "./imageSlice";

const {
	bx_vars: {
		//@ts-ignore
		API_URL,
	},
} = window;

interface iProgressEvent {
	loaded: number;
	total: number;
}

// interface Data {
// 	path: string;
// 	lastModified: number;
// 	name: string;
// 	size: number;
// 	type: string;
// 	webkitRelativePath: string;
// 	lastModifiedDate: {
// 		[key: string]: string;
// 	};
// }

interface iFile {
	[key: string]: Blob;
}

const readUploadedFileAsDataURL = (inputFile: iFile) => {
	const fileReader = new FileReader();

	return new Promise((resolve, reject) => {
		fileReader.onerror = () => {
			fileReader.abort();
			reject(new Error("Problem parsing input file."));
		};

		fileReader.onload = () => resolve(fileReader.result);
		fileReader.readAsArrayBuffer(inputFile[0]);
	});
};

export const imageUpload = createAsyncThunk("image/upload", async (image: any, thunkApi) => {
	const { id, userData } = image;
	const file = await readUploadedFileAsDataURL(userData);
	const token = getTokenFromStorage();

	const options = {
		onUploadProgress: (progressEvent: iProgressEvent) => {
			const { loaded, total } = progressEvent;
			let percent = Math.floor((loaded * 100) / total) - 1;
			let loadedConverted = ConvertToKB(loaded);
			let totalConverted = ConvertToKB(total);
			thunkApi.dispatch(load({ id: id, percent, loadedConverted, totalConverted }));
		},
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Bearer ${token}`,
		},
	};

	const state: any = thunkApi.getState();

	const outputType = state?.image?.outputType;
	const imageSize = state?.image?.imageSize;

	let width = 480;
	let height = 480;

	switch (imageSize) {
		case "portrait":
			height = 660;
			break;
		case "landscape":
			width = 660;
			break;
		default:
			break;
	}

	const response = await ImageAPI.post(
		`${API_URL}/v3/scale/${width}x${height}/${outputType}/cr`,
		file,
		options
	);

	if (response.status !== 200) {
		throw new Error("Failed to upload file");
	}

	thunkApi.dispatch(load({ id: id, percent: 100 }));

	return response.data;
});
