import { createSlice } from "@reduxjs/toolkit";
import { imageUpload } from "./thunks";
interface State {
	image: any;
	load: any;
	outputType: string;
	imageSize: string;
}

const initialState: State = {
	image: null,
	load: {},
	outputType: "",
	imageSize: "",
};

export const imageSlice = createSlice({
	name: "imageUpload",
	initialState,
	reducers: {
		changeOutputType: (state, action) => {
			state.outputType = action.payload;
		},
		changeImageSize: (state, action) => {
			state.imageSize = action.payload;
		},
		load: (state, action) => {
			state.load[action.payload.id] = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(imageUpload.pending, (state) => state);
		builder.addCase(imageUpload.fulfilled, (state) => state);
		builder.addCase(imageUpload.rejected, () => initialState);
	},
});

export const { load, changeOutputType, changeImageSize } = imageSlice.actions;

export default imageSlice.reducer;
