interface Theme {
	themeName: string;
	body: string;
	text: string;
}

export const lightTheme: Theme = {
	themeName: "light",
	body: "#FAFAFA",
	text: "#363537",
};

export const darkTheme: Theme = {
	themeName: "dark",
	body: "#363537",
	text: "#FAFAFA",
};
